<template>
  <div>
 <!-- v-on:submit.prevent="onSubmit"  @submit.prevent="validateUser" -->
    <form novalidate class="md-layout md-gutter md-alignment-center-center "  >
           
      <md-card class="md-layout-item md-size-90 md-small-size-100">
        <md-card-header>
          <div class="md-title">Cotizador De Salud</div>
           
        </md-card-header>

        <md-card-content>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('nombre_agente')">
                  <md-icon class="md-primary">support_agent</md-icon>
                <label for="agent-name">Nombre de Agente</label>
                <md-input  @focus="focusInput('nombre_agente')" name="agent-name" id="agent-name"
                            autocomplete="given-name" v-model="form.nombre_agente"
                            :disabled="sending || true "/>
              </md-field>
              <span class="md-error" v-if="!$v.form.nombre_agente.required && $v.form.nombre_agente.$dirty">Nombre de Agente es requerido</span>
              <span class="md-error" v-else-if="!$v.form.nombre_agente.minLength && $v.form.nombre_agente.$dirty">Nombre muy corto</span>
            </div>

            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('nombre_cliente')">
                  <md-icon class="md-primary">person_outline</md-icon>

                <label for="titular-name">Nombre de Titular</label>
                <md-input @focus="focusInput('nombre_cliente')" name="titular-name" id="titular-name" 
                  autocomplete="family-name" v-model="form.nombre_cliente" :disabled="sending" v-on:input="toUpperCase($event)"/>
                <span class="md-error" v-if="!$v.form.nombre_cliente.required && $v.form.nombre_cliente.$dirty">Nombre de titular requerido</span>
                <span class="md-error" v-else-if="!$v.form.nombre_cliente.minLength && $v.form.nombre_cliente.$dirty">Nombre Inválido</span>
              </md-field>
            </div>
          </div>
          <div class="md-layout md-gutter">
              <!-- <div class="md-layout-item md-small-size-100">
                <md-field :class="getValidationClass('cedula')">
                    <md-icon class="md-primary">assignment_ind</md-icon>
                  <label for="ci">CI</label>
                  <md-input @focus="focusInput('cedula')" name="ci" id="ci" autocomplete="given-name" v-model="form.cedula" :disabled="sending" />

                  
                  <span class="md-error" v-if="!$v.form.cedula.minLength && $v.form.cedula.$dirty">Ci inválido</span>
                </md-field>
              </div> -->

              
            <div class="md-layout md-gutter">

              <div class="md-layout-item md-size-50  md-small-size-100">
                  <md-field :class="getValidationClass('email_cliente')">
                      <md-icon class="md-primary">mail_outline</md-icon>
                      <label for="email_cliente">Email</label>
                      <md-input @focus="focusInput('email_cliente')" type="email_cliente" name="email_cliente" id="email_cliente" 
                        autocomplete="email_cliente" v-model="form.email_cliente" :disabled="sending" />
                    <span class="md-error" v-if="!$v.form.email_cliente.required && $v.form.email_cliente.$dirty">Email es requerido</span>
                    <span class="md-error" v-else-if="!$v.form.email_cliente.email_cliente && $v.form.email_cliente.$dirty">Email inválido</span>
                  </md-field>

              </div>

              <div class="md-layout-item md-size-50 md-small-size-100">
                <md-field :class="getValidationClass('telefono_cliente')">
                  <md-icon class="md-primary">phone_iphone</md-icon>
                  <label for="telefono_cliente">Teléfono</label>
                  <md-input @focus="focusInput('telefono_cliente')" name="telefono_cliente" id="telefono_cliente" autocomplete="family-name"
                            v-model="form.telefono_cliente" :disabled="sending" placeholder="04141234567"/>
                  <span class="md-error" v-if="!$v.form.telefono_cliente.required && $v.form.telefono_cliente.$dirty">Teléfono es requerido</span>
                  <span class="md-error" v-else-if="!$v.form.telefono_cliente.minLength && $v.form.telefono_cliente.$dirty">Numero de Teléfono muy corto</span>
                </md-field>
              </div>
            </div>

            <div class="md-layout-item md-size-20  md-small-size-100">
              <md-field :class="getValidationClass('edades')">
                <md-icon>
                    <button type="button" class="button-icon" v-on:click="addEdad">
                    <md-icon class="md-primary">add</md-icon>
                    </button>
                </md-icon>
                <label for="edad">Edades</label>
                <md-input type="number" name="edad" id="edad" v-model="edad"  :disabled="sending"
                      @keyup.enter="addEdad" v-on:change="addEdad"/>
                  <span class="md-error" v-if="!$v.form.edades.required && $v.form.edades.$dirty">
                    Edad es requerida
                  </span>
              </md-field>
            </div>

            <div class="md-layout-item md-size-30  md-small-size-100">
              <!-- <md-chips class="md-primary" v-model="form.edades" md-placeholder="Intoduzca una edad"
                            :md-auto-insert="true" :md-check-duplicated="false" :md-format="addEdad1">
                <label>Edades</label>
              </md-chips> -->
              <md-chip class="md-primary" v-for="(edad,index) in form.edades" :key="index" :md-deletable="true" @md-delete="onDeleteEdad">
                {{edad}}
              </md-chip>

            </div>

          </div>

          <div class="md-layout md-gutter">
          </div>

            <div class="md-layout md-gutter">

              <div class="md-layout-item md-small-size-100">
                <md-field>
                  <md-icon class="md-primary">corporate_fare</md-icon>

                  <label for="companies">Compañias</label>
                  <md-select v-model="form.empresas" name="companies" id="companies" multiple v-on:input="limiter($event,4)" 
                              :disabled="sending">
                    <md-option v-for="company in companies" :key="company.id"  :value="company.id">{{ company.nombre }}</md-option>
                  </md-select>
                </md-field>
              </div>
              <div class="md-layout-item md-small-size-100">
                <md-field>
                    <md-icon class="md-primary">price_check</md-icon>
                    <label for="sumas">Sumas Aseguradas</label>
                    <md-select v-model="form.coberturas" name="sumas" id="sumas" multiple
                                v-on:input="limiter($event,3)">
                      <md-option v-for="suma in coberturas" :key="suma.id" :value="suma.id" :disabled="sending">
                        {{ suma.cobertura }}
                      </md-option>
                    </md-select>
                  </md-field>
              </div>
            </div>

            <div class="md-layout md-gutter">
                <div class="md-layout-item md-small-size-100">
                    <md-switch v-model="allSelect" @change="changeAllSelects" class="md-primary" :disabled="sending">
                      Todos
                    </md-switch>
                </div>
                <div class="md-layout-item md-small-size-100">
                    <md-switch v-model="form.has_viajes" class="md-primary" :disabled="sending">
                      Asistencia en viajes
                    </md-switch>
                </div>
                <div class="md-layout-item md-small-size-100">
                    <md-switch v-model="form.has_funerario" class="md-primary" :disabled="sending">
                      Gastos funerario
                    </md-switch>
                </div>
                <div class="md-layout-item md-small-size-100">
                    <md-switch v-model="form.has_maternidad" class="md-primary" :disabled="sending">
                      Maternidad
                    </md-switch>
                </div>
                <div class="md-layout-item md-small-size-100">
                    <md-switch v-model="form.has_vida" class="md-primary" :disabled="sending">
                      Vida
                    </md-switch>
                </div>
                <div class="md-layout-item md-small-size-100">
                    <md-switch v-model="form.has_amd" class="md-primary" :disabled="sending">
                      Asistencia médica a domicilo
                    </md-switch>
                </div>
            </div>
            <div v-if="false" class="md-layout md-gutter">
                <div class="md-layout-item md-size-50 md-small-size-100">
                    <md-field>
                      <md-icon class="md-primary">%</md-icon>
                      <label for="descuento">Descuento</label>
                      <md-input type="number" name="descuento" id="descuento" v-model="form.descuento"  :disabled="sending"
                                @keyup.enter="addEdad"/>
                    </md-field>
                </div>
            </div>

        </md-card-content>


        <md-card-actions>
          <!-- type="button"    @click="validateUser" -->
          <md-button  type="button" class="md-primary" :disabled="sending" @click="validateForm">Crear Cotización</md-button>
        </md-card-actions>
        <md-progress-bar md-mode="indeterminate" v-if="sending" />
      </md-card>

      <md-snackbar :md-position="'center'" :md-duration="4000"
                    :md-active.sync="showSnackbar" md-persistent>
          <span>{{message}}</span>
        <md-button class="md-primary" @click="showSnackbar = false">Retry</md-button>
      </md-snackbar>
    </form>
    <div v-if="cotizaciones.length > 0" novalidate class="md-layout md-gutter md-alignment-center-center " style="margin-top:10px"  >
      <md-card class="md-layout-item md-size-90 md-small-size-100">
          <md-card-header>
            <div class="md-title">Cotizaciones Generadas</div>
            
          </md-card-header>

          <md-card-content>
            <div class="md-layout md-gutter">
              <div class="md-layout md-gutter md-alignment-left-center">
                <md-list class="md-layout-item md-size-100 md-small-size-100" v-if="cotizaciones.length > 0">
                    <!-- <md-list-item><b></b> </md-list-item> -->
                  <md-list-item v-for="(cotizacion, index) in cotizaciones" :key="index">
                      <span class="md-list-item-text">Cotizacion #{{cotizacion.cotizacion}}</span>

                    <md-button class="md-primary md-icon-button " v-on:click="sendCotizacion(cotizacion)" :disabled="sending_cotizacion">
                        <md-icon>send</md-icon>
                    </md-button>
                    <md-button class="md-primary md-icon-button " v-on:click="viewCotizacion(cotizacion.cotizacion)">
                        <md-icon>picture_as_pdf</md-icon>
                    </md-button>
                    
                    <div class="md-layout-item md-size-50">
                      <md-field>
                        <label for="food">Archivos adjuntos</label>
                        <md-select v-model="cotizacion.archivos" name="food" id="food"  multiple>
                          <md-optgroup v-for="empresa in empresas_archivos" :key="empresa.id" 
                            :label="empresa.nombre">
                            <md-option v-for="archivo in empresa.archivos" :key="archivo.id" :value="archivo.id">
                              {{archivo.nombre}}
                              <md-tooltip md-direction="top">{{archivo.nombre}}</md-tooltip>
                            </md-option>
                          </md-optgroup>
                        </md-select>
                      </md-field>
                    </div>
                  </md-list-item>
                </md-list>
              </div>
            </div>
          </md-card-content>
          <md-progress-bar md-mode="indeterminate" v-if="sending_cotizacion" />
      </md-card>

    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import { validationMixin } from 'vuelidate'
import {
  required,
  email,
  minLength,
  // maxLength
} from 'vuelidate/lib/validators'

export default {
  name: 'Cotizador',
  mixins: [validationMixin],
  components: {
    // HelloWorld
  },
  data: ()=>({
    mediaVisible: false,
    visible: false,
    showSnackbar: false,
    menuVisible: false,
    
    sending: false,
    sending_cotizacion: false,
    dateFormat:  'yyyy-MM-dd',
    cotizaciones: [],
    companies:[],
    coberturas:[],
    message: '',
    edad:null,
    empresas_archivos:[],
    allSelect: true,
    form: {
        nombre_agente:null,
        nombre_cliente:null,
        cedula:"",
        email_cliente: "",
        telefono_cliente: null,
        edades: [],
        empresas: [],
        coberturas:[],
        descuento: 0,
        has_vida: true,
        has_amd: true,
        has_viajes: true,
        has_funerario: true,
        has_maternidad: true,
        archivos: []
    },
    
  }),
  validations: {
    form: {
      nombre_agente: {
          required,
          minLength: minLength(3)
      },
      nombre_cliente: {
          required,
          minLength: minLength(3)
      },
      email_cliente:{
          email
      },
      cedula:{
          minLength: minLength(6)
      },
      telefono_cliente:{
          required,
          minLength: minLength(11)
      },
      edades:{
        required
      }
    }

  },
  mounted:function(){
    this.form.nombre_agente = `${this.getUser.first_name} ${this.getUser.last_name}`
    this.$api({url:'empresa/empresas/', method:'GET'})
    .then(resp => {
      this.companies = resp.data
      
    })
    // .catch(err => {
      
    // })
    this.$api({url:'empresa/coberturas/', method:'GET'})
    .then(resp => {
      this.coberturas = resp.data
      
    })
    // .catch(err => {
      
    // })

    this.$api({url:'empresa/empresas/archivos/', method:'GET'})
    .then(resp => {
      this.empresas_archivos = resp.data
      
    })
    // .catch(err => {
      
    // })
  },
  computed : {
    getUser: function(){ return this.$store.getters.authUser }
  }, 
  methods:{
    onDeleteEdad(index){
      if (index) {
        
        this.form.edades.splice(index,1)
      }
    },
    getValidationClass: function(fieldName) {
      const field = this.$v.form[fieldName]
      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty
        }
      }
    },
    addEdad: function(){
        if(this.edad == null || this.edad == ""){

            return
        }
        this.form.edades.push(this.edad);
        this.edad = null;
    },
    validateForm: function () {
      this.$v.$touch();
      

      if (!this.$v.$invalid) {
        this.onSubmit()
      }
    },
    focusInput: function (fieldName) {
      const field = this.$v.form[fieldName]

      if (field){
          field.$touch()
      }
    },
    limiter: function(e, maxInput) {
      if(e.length > maxInput) {
        this.message ="Selección máxima alcanzada";
        this.showSnackbar = true;
        e.pop()
      }
    },
    toUpperCase: function(e) {
      if (e==null || e ==undefined) {
        return
      }
      this.form.nombre_cliente = e.split(' ')
        .map((word) =>{
          if(word[0]){
            return word[0].toUpperCase() + word.substring(1).toLowerCase()
          }
          return word
        }).join(' ');
    },
    viewCotizacion: function (cotizacion_id) {
      window.open(`${this.$appConfig.adminUrl}empresa/cotizacion/pdf/${cotizacion_id}`, '_blank');
    },
    sendCotizacion: function (cotizacion) {
      this.sending_cotizacion = true
      const data = {cotizacion_id: cotizacion.cotizacion, archivos: cotizacion.archivos}
      this.$api({url:'empresa/cotizacion/send/', method:'POST', data:data})
      .then(resp => {
        this.sending_cotizacion = false
        if(resp.data['valid']){
              const data = resp.data
              this.message = data['message'];
              this.showSnackbar = true;
        }//else{
        //     this.clear()
        //     for(field in response.data['error']){
        //     this.errors[field] = response.data['error'][field]
        //     }
        // }

      })
     .catch(() => {
        this.sending_cotizacion = false
      })
    },
    clear: function(){
        this.form.nombre_cliente ="";
        this.form.cedula ="";
        this.form.email_cliente = "";
        this.form.telefono_cliente = "";
        this.form.edades = [];
        this.form.empresas = [];
        this.form.coberturas =[];
        this.form.descuento = 0;
        this.form.has_vida = true;
        this.form.has_viajes = true;
        this.form.has_funerario = true;
        this.form.has_maternidad = true;
        this.form.archivos= [];
        this.$v.$reset();
      
    },
    onSubmit: function () {
      
      const data = this.form
      this.sending = true
      this.$api({url:'empresa/cotizacion/', method:'POST', data:data})
      .then(resp => {
        this.clear()
        this.sending = false
        const data = resp.data
        this.cotizaciones.push({cotizacion:data.cotizacion_id, archivos:[]})
        this.message = data['message'];
        this.showSnackbar = true;

      })
      .catch(() => {
        this.sending = false
      })
  
    },
    changeAllSelects: function(e){
      this.form.has_viajes = e;
      this.form.has_funerario = e;
      this.form.has_maternidad = e;
      this.form.has_vida = e;
      this.form.has_amd = e;
    }
  }
}
</script>

<style lang="scss">
  .button-icon {
    border-style: none;
    background-color: #ffffff;
  }
  .md-content{
    background-color: #f7f7f7 !important;
  }
  .md-error{
    left: 38px !important;
  }
</style>