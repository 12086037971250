<template>
  <div id="app">
    <div class="page-container">
      <md-app md-mode="reveal">
        <md-app-toolbar class="md-primary">
          <div class="md-toolbar-section-start">  
            <md-button v-if="isLoggedIn"  class="md-icon-button" @click="menuVisible = !menuVisible">
                <md-icon>menu</md-icon>
            </md-button>
            <span class="md-title">Cotizador</span>
               
          </div>
          <div class="md-toolbar-section-end">
            <p>{{getUser.first_name}} {{getUser.last_name}} </p>
            <md-button class="md-icon-button">
                <md-icon>person</md-icon>
            </md-button>
            <md-button @click="logout" class="md-icon-button"  v-if="isLoggedIn">
                    <md-icon>logout</md-icon>
                <md-tooltip md-direction="bottom">Cerrar sesión</md-tooltip>
            </md-button>

            <!-- <md-button class="md-icon-button">
                <md-icon>more_vert</md-icon>
            </md-button> -->
                       
            </div>
        </md-app-toolbar>
<!-- {# :md-active.sync="menuVisible"  #} -->
        <md-app-drawer v-if="isLoggedIn"  :md-active.sync="menuVisible" md-permanent="clipped"  class="md-drawer">
          <md-list class="drawer-list">
            <md-list-item  href="/cotizador/">
                <md-icon>home</md-icon>
                <span class="md-list-item-text">Home</span>
            </md-list-item>
            <!-- <md-list-item  href="/cotizador-vida/">
                <md-icon>home</md-icon>
                <span class="md-list-item-text">Cotizador Vida</span>
            </md-list-item>
            <md-list-item  href="/cotizador-funerario/">
                <md-icon>home</md-icon>
                <span class="md-list-item-text">Cotizador Funerarios</span>
            </md-list-item> -->
            <md-list-item href="/cotizaciones/">
                <md-icon>move_to_inbox</md-icon>
                <span class="md-list-item-text">Cotizaciones</span>
            </md-list-item>
            
          </md-list>
                
        </md-app-drawer>

        <md-app-content>
            <router-view/>
        </md-app-content>

      </md-app>
        <div class="footer">
            <p><span class="ct-footer-year">2021</span> © All rights reserved by JLCamejo – Design By
                <a href="https://orcafusioncreativa.com/">Orca Fusión Creativa</a>
            </p>
        </div>
    </div>
    
  </div>
</template>
<script>
  export default {
    data: ()=>({
      menuVisible: false
    }),
    created: function () {
      this.$http.interceptors.response.use(undefined, function (err) {
        return new Promise(function () {

          if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
            this.$store.dispatch('logout')
          }
          throw err;
        });
      });
    },
    computed : {
      isLoggedIn : function(){ return this.$store.getters.isLoggedIn},
      getUser: function(){ return this.$store.getters.authUser }
    },
    mounted: function () {
      
    },
    methods: {
      
      logout: function () {
        this.$store.dispatch('logout')
        .then(() => {
          // this.$router.push('/login')
        })
      }
    },
  }
</script>

<style lang="scss">
  @import "~vue-material/dist/theme/engine"; // Import the theme engine

  @include md-register-theme("default", (
    primary: #0e2a47,//md-get-palette-color(blue, A200), // The primary color of your application
    //accent:  #d1cbcb//md-get-palette-color(red, A200) // The accent or secondary color
  ));

  @import "~vue-material/dist/theme/all";
  .md-app {
      min-height: 90vh;
  // {#    border: 1px solid rgba(#000, .12);#}
    }
  .md-tooltip{
    font-size: 13px;
  }
  .md-menu-content{
    max-width: 350px;
  }
  .md-app-internal-drawer .md-app-scroller, .md-app-scroller{
    overflow: hidden;
  }
   .footer{
       height: 10vh;
       background-color: #0e2a47;
       font-size: 14px;
       color: #FFFFFF;
       text-align: center;
       padding: 2vh;


   }
   .footer a{
       color: #01CDFB !important;
       font-weight:  600;
   }
  .md-drawer {
    width: 250px;
    max-width: calc(100vw - 125px);
    background-image: url(https://jlcamejo.com/wp-content/uploads/2020/05/h4-bg-section4-scaled.jpg);
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .drawer-list {
   background-color: #fff0 !important;
      padding: 10px;
  }
</style>
