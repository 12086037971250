import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueMaterial from 'vue-material'
import Axios from 'axios'
import store from './vuex/store'
import {config}  from './config';
import apiCall from './utils/api'


Vue.prototype.$http = Axios;
Vue.prototype.$api = apiCall;
const token = localStorage.getItem('token')
if (token) {
  Axios.defaults.headers.common['Authorization'] = `Token ${token}`
}
Vue.use(VueMaterial)

Vue.config.productionTip = false
Vue.prototype.$appConfig = config

new Vue({
  router,
  render: h => h(App),
  store
}).$mount('#app')
