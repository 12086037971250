import Axios from "axios";
import {config} from "../config"

const apiCall = ({ url, method, data, params, responseType='json'}) =>{
  url = `${config.apiUrl}/${url}`
  return Axios({
    method,
    url,
    data,
    params,
    responseType
  })

}

export default apiCall;
