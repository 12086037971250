import Vue from 'vue'
import VueRouter from 'vue-router'
import Cotizador from '../views/Cotizador.vue'
import store from '../vuex/store'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Cotizador,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },
  {
    path: '/cotizador',
    name: 'Cotizador',
    meta: {
      requiresAuth: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Cotizador.vue')
  },
  {
    path: '/cotizador-vida',
    name: 'CotizadorVida',
    meta: {
      requiresAuth: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/CotizadorVida.vue')
  },
  {
    path: '/cotizador-funerario',
    name: 'CotizadorFunerario',
    meta: {
      requiresAuth: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/CotizadorFunerario.vue')
  },
  {
    path: '/cotizaciones',
    name: 'Cotizaciones',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/CotizacionesList.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name === 'Login' && store.getters.isLoggedIn){
    next('/')
    return
  } 
  if(to.matched.some(record => record.meta.requiresAuth) ) {
    if(store.getters.isLoggedIn){
      next()
      return
    }
    next('/login')
  } else {
    next()
    
  }
})

export default router
