import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import apiCall from '../utils/api'
import router from '../router'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    status: '',
    token: localStorage.getItem('token') || '',
    user : localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')): {},
   
  },
  mutations: {

    auth_request(state){
        state.status = 'loading'
    },
    auth_success(state, data){
        state.status = 'success'
        state.token = data.token
        state.user = data
    },
    auth_error(state){
        state.status = 'error'
    },
    logout(state){
        state.status = ''
        state.token = ''
        state.user = {}
    },
      
  },
  actions: {
    login({commit}, user){
        return new Promise((resolve, reject) => {
          commit('auth_request')
          apiCall({url: 'auth/user/login/', data: user, method: 'POST' })
          .then(resp => {
            const token = resp.data.token
            const data = resp.data
            localStorage.setItem('token', token)
            localStorage.setItem('user', JSON.stringify(data))
            axios.defaults.headers.common['Authorization'] = `Token ${token}`
            
            commit('auth_success', data)
            resolve(resp)
          })
          .catch(err => {
            commit('auth_error')
            localStorage.removeItem('token')
            reject(err)
          })
        })
    },
    logout({commit}){
        return new Promise((resolve) => {
          commit('logout')
          router.push('/login')
          localStorage.removeItem('token')
          localStorage.removeItem('user')
          delete axios.defaults.headers.common['Authorization']
          resolve()
        })
      }
  },
  getters : {
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status,
    authUser: state => state.user,
  }
})